import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from '@/styles/Search/SearchInput.module.js'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import PlaceIcon from '@mui/icons-material/Place'
import NearMeIcon from '@mui/icons-material/NearMe'
import LocalDiningIcon from '@mui/icons-material/LocalDining'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import Immutable, { List, Map } from 'immutable'
import AutocompleteInput from '@/components/Search/AutocompleteInput'
import classnames from 'classnames'
import { Router } from '@/routes'
import GAEventTracker from '@/libs/GAEventTracker'

class SearchInput extends PureComponent {
  constructor(props) {
    super(props)
    this._handlePlaceValueChange = this._handlePlaceValueChange.bind(this)
    this._handleKeywordValueChange = this._handleKeywordValueChange.bind(this)
    this._handleKeywordEnterPress = this._handleKeywordEnterPress.bind(this)
    this._handlePlaceEnterPress = this._handlePlaceEnterPress.bind(this)
    this._renderPlaceSuggestion = this._renderPlaceSuggestion.bind(this)
    this._renderKeywordSuggestion = this._renderKeywordSuggestion.bind(this)
    this._getSelectedPlaceText = this._getSelectedPlaceText.bind(this)
    this._getSelectedKeywordText = this._getSelectedKeywordText.bind(this)
    this._handleSearchPress = this._handleSearchPress.bind(this)
    this._handleKeywordFocus = this._handleKeywordFocus.bind(this)
    this._handlePlaceFocus = this._handlePlaceFocus.bind(this)
    this._handlePlaceBlur = this._handlePlaceBlur.bind(this)
    this._closeComposingMode = this._closeComposingMode.bind(this)
    this._handleClickMobileHeaderAction = this._handleClickMobileHeaderAction.bind(this)
    this._onRemovePlace = this._onRemovePlace.bind(this)
    this._focusPlaceInput = this._focusPlaceInput.bind(this)
    this.state = {
      inputedKeyword: props.currentKeyword,
      inputedPlace: props.currentPlaceName,
      inputedKeywordBeforeComposing: props.currentKeyword,
      inputedPlaceBeforeComposing: props.currentPlaceName,
      isComposingSearch: false,
      defaultPlaceSuggestions: Immutable.fromJS([]),
      defaultRestaurantSuggestions: Immutable.fromJS([{ description: '所有餐廳', type: 'all-restaurant' }]),
      foregroundList: 'keyword',
      isPlaceFocused: false
    }
  }
  componentWillReceiveProps(nextProps) {
    const { currentKeyword, currentPlaceName } = this.props
    if(nextProps.currentKeyword !== currentKeyword) {
      this.setState({
        inputedKeyword: nextProps.currentKeyword
      })
    }
    if(nextProps.currentPlaceName !== currentPlaceName) {
      this.setState({
        inputedPlace: nextProps.currentPlaceName
      })
    }
  }
  render() {
    const { keywordsSuggestions, placeSuggestions, citySuggestions, getPlaceSuggestions,
      getKeywordSuggestions, restaurantsSuggestions, classes, currentKeyword } = this.props

    const { inputedKeyword, inputedPlace, isComposingSearch, defaultPlaceSuggestions,
      foregroundList, defaultRestaurantSuggestions } = this.state

    return (
      <div
        className={classnames('search-input', {
          [classes.root]: classes.root,
          composing: isComposingSearch
        })}>
        <style jsx>{styles}</style>
        <div className='search-input-bg' />
        <div className='search-input-header'>
          <div
            className='mobile-header-close'
            onClick={this._closeComposingMode}>
            取消
          </div>
          <div className='mobile-header-title'>搜尋餐廳</div>
          <div
            className='mobile-header-action'
            onClick={this._handleClickMobileHeaderAction}>
              搜尋
          </div>
        </div>
        <div className='input-group'>
          <AutocompleteInput
            key={currentKeyword}
            className='keyword-input'
            classes={{
              root: classnames('autocomplete-root autocomplete-keyword', {
                foreground: foregroundList === 'keyword'
              }),
              suggestions: classnames('autocomplete-suggestions', { 'composing-search': isComposingSearch }),
              input: 'autocomplete-input'
            }}
            placeholder='美食分類、餐廳'
            type='search'
            value={inputedKeyword}
            getSelectedText={this._getSelectedKeywordText}
            setValue={this._handleKeywordValueChange}
            getSuggestions={getKeywordSuggestions}
            suggestions={
              defaultRestaurantSuggestions
                .concat(restaurantsSuggestions)
                .concat(keywordsSuggestions)
            }
            renderSuggestion={this._renderKeywordSuggestion}
            onEnterPress={this._handleKeywordEnterPress}
            onFocus={this._handleKeywordFocus} />
          <SearchIcon className='input-icon input-search-icon' />
          <RestaurantIcon className='input-icon restaurant-icon' />
          <div className='divider' />
          <div style={{ display: 'flex', flex: 1, height: '100%', alignItems: 'center', width: '100%' }}>
            {
              this._shouldShowPlaceNameTag() && (
                <div className='current-place-tag' onClick={this._focusPlaceInput}>
                  <span className='current-place-tag-content'>{inputedPlace}</span>
                  <CloseIcon className='input-close-icon' onClick={this._focusPlaceInput} />
                </div>
              )
            }
            <AutocompleteInput
              ref={el => this._placeInput = el}
              className='place-input'
              classes={{
                root: classnames('autocomplete-root autocomplete-place', {
                  foreground: foregroundList === 'place'
                }),
                suggestions: classnames('autocomplete-suggestions', { 'composing-search': isComposingSearch }),
                input: 'autocomplete-input'
              }}
              placeholder={!inputedPlace ? '搜尋地點' : ''}
              type='search'
              value={!this._shouldShowPlaceNameTag() ? inputedPlace : ''}
              setValue={this._handlePlaceValueChange}
              getSelectedText={this._getSelectedPlaceText}
              getSuggestions={getPlaceSuggestions}
              suggestions={
                defaultPlaceSuggestions
                  .concat(citySuggestions)
                  .concat(placeSuggestions)
              }
              renderSuggestion={this._renderPlaceSuggestion}
              onEnterPress={this._handlePlaceEnterPress}
              onFocus={this._handlePlaceFocus }
              onBlur={this._handlePlaceBlur} />
            <PlaceIcon className='input-icon place-icon' />
          </div>

          <Button
            className='search-button'
            onClick={this._handleSearchPress}
            variant='contained'
            classes={{
              root: 'search-button',
              label: 'search-button-text'
            }}>
            <SearchIcon className='search-icon' />
          </Button>
        </div>
      </div>
    )
  }
  _focusPlaceInput() {
    this._placeInput.focus()
  }
  _shouldShowPlaceNameTag() {
    const { isPlaceFocused, inputedPlace } = this.state
    return !isPlaceFocused && inputedPlace && inputedPlace !== '目前位置'
  }
  _handleClickMobileHeaderAction() {
    this._handleSearchPress()
    this.setState({
      isComposingSearch: false
    })
  }
  _closeComposingMode() {
    this.setState(state => ({
      isComposingSearch: false,
      inputedKeyword: state.inputedKeywordBeforeComposing,
      inputedPlace: state.inputedPlaceBeforeComposing
    }))
  }
  _handleKeywordFocus() {
    GAEventTracker.sendEvent({
      category: 'SearchInput',
      action: 'Focus關鍵字輸入框'
    })

    this.setState(state => ({
      foregroundList: 'keyword',
      isComposingSearch: true,
      inputedKeywordBeforeComposing: state.inputedKeyword,
      inputedPlaceBeforeComposing: state.inputedPlace,
    }))
  }
  _ensureMapkit = () => {
    function initMapKit() {
      mapkit.init({
        authorizationCallback: (done) => {
          fetch('/map-kit-token')
            .then((res) => res.text())
            .then(done)
        },
      })
    }
    (function(w, d, s, src, n) {
      var js, ajs = d.getElementsByTagName(s)[0];
      if (d.getElementById(n)) return;
      js = d.createElement(s); js.id = n;
      w[n] = w[n] || function() { (w[n].q = w[n].q || []).push(arguments) }; w[n].l = 1 * new Date();
      js.async = 1; js.src = src; js.onload=initMapKit; ajs.parentNode.insertBefore(js, ajs)
    })(window, document, 'script', 'https://cdn.apple-mapkit.com/mk/5.29.0/mapkit.js', 'MapKit');
  }
  _handlePlaceFocus() {
    GAEventTracker.sendEvent({
      category: 'SearchInput',
      action: 'Focus地點輸入框'
    })

    this._ensureMapkit()

    this.setState({
      foregroundList: 'place',
      defaultPlaceSuggestions: Immutable.fromJS([{ description: '目前位置', type: 'current-location' }]),
      isPlaceFocused: true
    })
  }
  _handlePlaceBlur() {
    this.setState({
      isPlaceFocused: false
    })
  }
  _getSelectedPlaceText({ selectedIndex }) {
    const { placeSuggestions, citySuggestions } = this.props
    const { defaultPlaceSuggestions } = this.state
    if(selectedIndex == 0) {
      return '目前位置'
    }
    return selectedIndex > citySuggestions.size - 1 + defaultPlaceSuggestions.size ? (
      placeSuggestions.getIn([selectedIndex - citySuggestions.size - defaultPlaceSuggestions.size, 'description'])
    ) : (
      this._getCityDisplay(selectedIndex - defaultPlaceSuggestions.size)
    )
  }
  _getCityDisplay(index) {
    const { citySuggestions } = this.props
    return `${citySuggestions.getIn([index, 'city'])}${citySuggestions.getIn([index, 'area']) || ''}`
  }
  _getSelectedKeywordText({ selectedIndex }) {
    const { keywordsSuggestions, restaurantsSuggestions } = this.props
    const { defaultRestaurantSuggestions } = this.state
    if (selectedIndex < defaultRestaurantSuggestions.size ) {
      // Return value for all-restaurant
      return ''
    }
    const restaurantIndex = selectedIndex - defaultRestaurantSuggestions.size
    if (restaurantIndex < restaurantsSuggestions.size) {
      return restaurantsSuggestions.getIn([restaurantIndex, 'name'])
    }
    const suggestionIndex = restaurantIndex - restaurantsSuggestions.size
    return keywordsSuggestions.get(suggestionIndex)
  }
  _renderPlaceSuggestion({ suggestion, isActive }) {
    if(suggestion.get('type') === 'current-location') {
      return (
        <div
          className={classnames('place-item current-location', {
            'place-item-active': isActive
          })}>
          <style jsx>{styles}</style>
          <NearMeIcon className='nearme-icon' />
          { suggestion.get('description') }
        </div>
      )
    } else if(suggestion.has('locationType')) {
      return (
        <div
          className={classnames('place-item', {
            'place-item-active': isActive
          })}>
          <style jsx>{styles}</style>
          {`${suggestion.get('city')}${suggestion.get('area') || ''}`}
        </div>
      )
    } else {
      return (
        <div
          className={classnames('place-item', {
            'place-item-active': isActive
          })}>
          <style jsx>{styles}</style>
          {suggestion.get('description')}
          <div className='item-info'>
            { suggestion.get('secondaryText') }
          </div>
        </div>
      )
    }
  }
  _renderKeywordSuggestion({ suggestion, isActive }) {
    if(typeof suggestion !== 'string' && suggestion.get('type') === 'all-restaurant') {
      return (
        <div
          className={classnames('keyword-item all-restaurant', {
            'keyword-item-active': isActive
          })}>
          <style jsx>{styles}</style>
          <LocalDiningIcon className='localdining-icon' />
          { suggestion.get('description') }
        </div>
      )
    }
    const isRestaurant = typeof suggestion !== 'string'
    return isRestaurant ? (
      <div
        className={classnames('keyword-item', 'restaurant-suggestion',{
          'keyword-item-active': isActive
        })}
        key={suggestion}>
        <style jsx>{styles}</style>
        <img className='item-photo' src={suggestion.get('coverUrl')} />
        <div className='item-content'>
          <div className='item-title'>{ suggestion.get('name') }</div>
          <div className='item-info'>
            { suggestion.get('address') }
          </div>
        </div>
      </div>
    ) : (
      <div
        className={classnames('keyword-item', {
          'keyword-item-active': isActive
        })}
        key={suggestion}>
        <style jsx>{styles}</style>
        { suggestion }
      </div>
    )
  }
  _handleKeywordEnterPress({ value, suggestion }) {
    const { search, clearKeywordSuggestions } = this.props
    clearKeywordSuggestions()
    if(suggestion instanceof Map) {
      if (suggestion.get('type') === 'all-restaurant') {
        GAEventTracker.sendEvent({
          category: 'SearchInput',
          action: '由輸入框更改關鍵字',
          label: '所有餐廳'
        })
        this.setState({
          isComposingSearch: false
        })
        search({
          keyword: '',
          keepPlace: true
        })
        return
      }
      GAEventTracker.sendEvent({
        category: 'SearchInput',
        action: '由輸入框建議進入餐廳',
        label: value
      })
      Router.pushRoute(`/restaurant/${suggestion.get('id')}`)
    } else {
      GAEventTracker.sendEvent({
        category: 'SearchInput',
        action: '由輸入框更改關鍵字',
        label: value
      })
      this.setState({
        isComposingSearch: false
      })
      search({
        keyword: value,
        keepPlace: true
      })
    }
  }
  _handlePlaceEnterPress({ value, suggestion }) {
    GAEventTracker.sendEvent({
      category: 'SearchInput',
      action: '由輸入框更改地點',
      label: value
    })

    const { search, clearPlaceSuggestions, mapGLBound } = this.props
    let { inputedKeyword } = this.state
    clearPlaceSuggestions()
    this.setState({
      isComposingSearch: false
    })
    if(suggestion) {
      inputedKeyword = suggestion.get('area') ? suggestion.get('area') : inputedKeyword

      search({
        keyword: inputedKeyword,
        sw: suggestion.get('sw'),
        ne: suggestion.get('ne'),
        latlng: suggestion.get('latlng'),
        location: suggestion.get('city'),
        sublocation: '',
        place: suggestion.get('description') === '目前位置' ? 'current' : suggestion.get('description')
      })
    } else if(value === '地圖範圍') {
      search({
        keyword: inputedKeyword,
        keepPlace: !mapGLBound.get('sw') || !mapGLBound.get('ne'),
        sw: mapGLBound.get('sw') ? Immutable.fromJS({
          lat: mapGLBound.get('sw').split(',')[0],
          lng: mapGLBound.get('sw').split(',')[1]
        }) : null,
        ne: mapGLBound.get('ne') ? Immutable.fromJS({
          lat: mapGLBound.get('ne').split(',')[0],
          lng: mapGLBound.get('ne').split(',')[1],
        }) : null
      })
    } else {
      search({
        keyword: inputedKeyword,
        place: value === '目前位置' ? 'current' : value
      })
    }
  }
  _handleSearchPress() {
    const { search, mapGLBound } = this.props
    const { inputedKeyword, inputedPlace } = this.state
    GAEventTracker.sendEvent({
      category: 'SearchInput',
      action: '由按鈕執行搜尋',
      label: inputedKeyword
    })
    if( inputedPlace === '地圖範圍') {
      search({
        keyword: inputedKeyword,
        keepPlace: !mapGLBound.get('sw') || !mapGLBound.get('ne'),
        sw: mapGLBound.get('sw') ? Immutable.fromJS({
          lat: mapGLBound.get('sw').split(',')[0],
          lng: mapGLBound.get('sw').split(',')[1]
        }) : null,
        ne: mapGLBound.get('ne') ? Immutable.fromJS({
          lat: mapGLBound.get('ne').split(',')[0],
          lng: mapGLBound.get('ne').split(',')[1],
        }) : null
      })
    } else {
      search({
        keyword: inputedKeyword,
        place: inputedPlace === '目前位置' ? 'current' : inputedPlace
      })
    }
  }
  _handlePlaceValueChange({ value }) {
    this.setState({
      inputedPlace: value || ''
    })
  }
  _handleKeywordValueChange({ value }) {
    this.setState({
      inputedKeyword: value || ''
    })
  }
  _onRemovePlace() {
    const { search } = this.props
    const { inputedKeyword } = this.state
    search({
      keyword: inputedKeyword,
      place: null
    })
  }
}

SearchInput.defaultProps = {
  currentPlaceName: '',
  currentKeyword: '',
  classes: {}
}

SearchInput.propTypes = {
  currentPlaceName: PropTypes.string.isRequired,
  currentKeyword: PropTypes.string.isRequired,
  restaurantsSuggestions: PropTypes.instanceOf(List).isRequired,
  keywordsSuggestions: PropTypes.instanceOf(List).isRequired,
  placeSuggestions: PropTypes.instanceOf(List).isRequired,
  citySuggestions: PropTypes.instanceOf(List).isRequired,
  getKeywordSuggestions: PropTypes.func.isRequired,
  getPlaceSuggestions: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearKeywordSuggestions: PropTypes.func.isRequired,
  clearPlaceSuggestions: PropTypes.func.isRequired,
  mapGLBound: PropTypes.instanceOf(Map).isRequired,
  classes: PropTypes.object
}

export default SearchInput
