import PropTypes from 'prop-types'
import styles from '@/styles/header/UnauthedHeader.module.js'
import React, { Component } from 'react'
import { Map } from 'immutable'
import classnames from 'classnames'
import LazyHydrate from "react-lazy-hydration";

import IconButton from '@mui/material/IconButton'
import AppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import SearchBar from '@/components/SearchBar'
import SvgImage from '@/components/SvgImage'
import Button from '@mui/material/Button'
import DropDownMenu from './DropDownMenu'
import Card from '@mui/material/Card'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import FaceIcon from '@mui/icons-material/AccountCircleOutlined'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import withLoginInfo from '@/hocs/withLoginInfo'
import GAEventTracker from '@/libs/GAEventTracker'
import getOpenLink from '@/libs/getOpenLink'

import SubHeader from '@/components/header/SubHeader'

class UnauthedHeader extends Component {
  constructor(props) {
    super(props)
    const { lazyRender } = props
    this.state = {
      renderHead: !lazyRender,
    }
  }
  componentDidMount() {
    this.setState({renderHead: true})
  }
  render() {
    const {
      renderCenter,
      classes,
      isVisiableCityAndCategoryMenu,
      isVisiableMenu,
      isVisibleUser,
      isVisibleDropdown,
      location,
      renderRight,
      renderLeft,
      setLoginModalOpenStatus,
      currentUser,
      headerId,
    } = this.props

    let inviteBloggerLink = '/why-ifoodie#feature-highlights'
    if (currentUser.get('isBusiness')) {
      inviteBloggerLink = '/business/case'
    }

    if (!this.state.renderHead) {
      return (
      <div className='header-frame loading'>
        <style jsx>{styles}</style>
        <div className="tool-bar-root">
          <div className="tool-bar">
          <div className="left">
            {renderLeft ? (
              renderLeft()
            ): (
              <a href="/">
                <SvgImage
                  className="ifoodie-logo"
                  svgName="ifoodie_logo"
                  alt="ifoodie.tw"
                />
              </a>
            )
          }
          </div>
          <div className="center"></div>
          <div className="right">
            {isVisibleDropdown && (
              <li className="drop-down-menu">
                <IconButton
                  id='menu-button'
                  style={{ color: 'white' }}
                  aria-haspopup="true"
                  >
                  <MenuIcon />
                </IconButton>
              </li>
            )}
          </div>
          </div>
        </div>
      </div>
      )
    }

    return (
      <LazyHydrate whenIdle>
      <div className="header-frame" id={headerId}>
        <style jsx>{styles}</style>
        <AppBar
          position="fixed"
          elevation={0}
          color="primary"
          className={`app-bar ${!!classes.root ? classes.root : 'default'}`}>
          <Toolbar
            classes={{
              root: 'tool-bar-root',
            }}>
            <div className="tool-bar">
              <div className="left">
                {
                  renderLeft ? (
                    renderLeft()
                  ): (
                    <a href="/">
                      <SvgImage
                        className="ifoodie-logo"
                        svgName="ifoodie_logo"
                        alt="ifoodie.tw"
                      />
                    </a>
                  )
                }
              </div>
              <div className="center">{renderCenter()}</div>
              <div className="right">
                {typeof renderRight === 'function' ? (
                  renderRight()
                ) : (
                  <React.Fragment>
                    {isVisiableCityAndCategoryMenu && this.state.mounted && (
                      <SubHeader location={location} />
                    )}
                    <ul className="navigation-list">
                      {this._isLoggedin() ? null : (
                        <React.Fragment>
                          <li className="navigation-item">
                            <Button
                              size="medium"
                              onClick={() => {
                                setLoginModalOpenStatus({ isOpen: true })
                              }}
                              classes={{
                                root: 'navigation-btn',
                                text: 'navigation-btn__label',
                              }}>
                              登入
                            </Button>
                          </li>
                          <div className="navigation-divider" />
                        </React.Fragment>
                      )}
                      {isVisiableMenu && (
                        <>
                          <li className="navigation-item">
                            <Button
                              href="/blogger"
                              size="medium"
                              onClick={this._handleMenuClick('發表食記')}
                              classes={{
                                root: 'navigation-btn',
                                text: 'navigation-btn__label',
                              }}>
                              發表食記
                            </Button>
                          </li>
                          <div className="navigation-divider" />
                          <li
                            className={classnames(
                              'navigation-item app-download-item',
                              { hide: this._isLoggedin() }
                            )}>
                            <Button
                              className="app-download-btn"
                              size="medium"
                              onClick={this._handleMenuClick('下載App')}
                              classes={{
                                root: 'navigation-btn',
                                text: 'navigation-btn__label',
                              }}>
                              <a href="/app" className="header-app-link" onClick={this._handleOpenInApp}>
                                <span id="header-app-link">在 APP 開啟</span>
                              </a>
                            </Button>
                            <div className="help-bubble">
                              <div
                                className="help-bubble-outer-dot"
                                id="help-bubble">
                                <div className="help-bubble-inner-dot" />
                              </div>
                            </div>
                          </li>
                          <li className="navigation-item navigation-item-reserve">
                            <Button
                              href="https://ifoodie.tw/app"
                              className="btn-go-reserve"
                              target="_blank"
                              onClick={this._handleMenuClick('下載App')}
                              classes={{
                                root: 'navigation-btn',
                                text: 'navigation-btn__label',
                              }}>
                              下載App
                            </Button>
                            <div className="help-bubble">
                              <div className="help-bubble-outer-dot">
                                <div className="help-bubble-inner-dot" />
                              </div>
                            </div>
                          </li>
                          <div className="navigation-divider" />
                          <li className="navigation-item">
                            <Button
                              href="/c/list/all"
                              size="medium"
                              onClick={this._handleMenuClick('專欄文章')}
                              classes={{
                                root: 'navigation-btn',
                                text: 'navigation-btn__label',
                              }}>
                              專欄文章
                            </Button>
                          </li>
                          <div className="navigation-divider" />
                          <li className="navigation-item navigation-item-store">
                            <Button
                              size="medium"
                              classes={{
                                root: 'navigation-btn',
                                text: 'navigation-btn__label',
                              }}>
                              店家專區
                              <KeyboardArrowDownIcon className="icon-arrow-down" />
                            </Button>
                            <div className="hover-items">
                              <Card className="hover-items-content">
                                <ul>
                                  <li>
                                    <Button
                                      onClick={this._handleMenuClick(
                                        '行銷方案'
                                      )}
                                      className="black-color"
                                      target="_blank"
                                      href="/why-ifoodie">
                                      行銷方案
                                    </Button>
                                  </li>
                                  <li>
                                    <Button
                                      onClick={this._handleMenuClick(
                                        '邀請部落客'
                                      )}
                                      className="black-color"
                                      href={inviteBloggerLink}>
                                      邀請部落客
                                    </Button>
                                  </li>
                                  <li>
                                    <Button
                                      onClick={this._handleMenuClick(
                                        '店家加入'
                                      )}
                                      className="black-color"
                                      target="_blank"
                                      href="/why-ifoodie">
                                      店家加入
                                    </Button>
                                  </li>
                                </ul>
                              </Card>
                            </div>
                          </li>
                          <div className="navigation-divider" />
                          <li className="navigation-item navigation-item-ranking-charts">
                            <Button
                              classes={{
                                root: 'navigation-btn',
                                text: 'navigation-btn__label',
                              }}>
                              排行榜
                              <KeyboardArrowDownIcon className="icon-arrow-down" />
                            </Button>
                            <div className="hover-items">
                              <Card className="hover-items-content">
                                <ul>
                                  <li>
                                    <Button
                                      onClick={this._handleMenuClick('週排行')}
                                      className="weekly black-color"
                                      href="/ranking/weekly/">
                                      週排行
                                    </Button>
                                  </li>
                                  <li>
                                    <Button
                                      onClick={this._handleMenuClick('月排行')}
                                      className="monthly black-color"
                                      href="/ranking/monthly/">
                                      月排行
                                    </Button>
                                  </li>
                                </ul>
                              </Card>
                            </div>
                          </li>
                        </>
                      )}
                      {this._isLoggedin() && isVisibleUser && (
                        <React.Fragment>
                          <div className="navigation-divider" />
                          <li className="avatar-item">
                            <a
                              href={`/u/${currentUser.get('id')}`}
                              className="avatar"
                              style={{
                                backgroundImage: `url("${this._getProfileImageUrl()}")`,
                              }}
                            />

                            <div className="hover-items">
                              <Card className="hover-items-content">
                                <ul>
                                  <li className="show-on-mobile">
                                    <Button
                                      className="profile-btn"
                                      onClick={this._handleMenuClick(
                                        '個人檔案'
                                      )}
                                      href={`/u/${currentUser.get('id')}`}>
                                      <FaceIcon className="profile-icon" />{' '}
                                      個人檔案
                                    </Button>
                                  </li>
                                  <li className="show-on-mobile">
                                    <Button
                                      className="profile-btn"
                                      onClick={this._handleMenuClick(
                                        '珍藏項目'
                                      )}
                                      href={`/u/${currentUser.get(
                                        'id'
                                      )}/saved`}>
                                      <BookmarkBorderIcon className="profile-icon" />{' '}
                                      珍藏項目
                                    </Button>
                                  </li>
                                </ul>
                              </Card>
                            </div>
                          </li>
                        </React.Fragment>
                      )}
                      {isVisibleDropdown && (
                        <li className="drop-down-menu">
                          <DropDownMenu
                            currentUser={currentUser}
                            setLoginModalOpenStatus={setLoginModalOpenStatus}
                          />
                        </li>
                      )}
                    </ul>
                  </React.Fragment>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      </LazyHydrate>
    )
  }
  _isLoggedin = () => {
    const { currentUser } = this.props
    return !!currentUser.get('id')
  }
  _getProfileImageUrl = () => {
    const { currentUser } = this.props
    return currentUser.get('thumb')
  }
  _handleOpenInApp = (e) => {
    const openLink = getOpenLink()
    e.preventDefault()
    console.log('openLink', openLink)
    window.location = openLink
    setTimeout(function () {
      window.location = 'https://ifoodie.tw/app'
    }, 1000)
  }
  _handleMenuClick(label) {
    return () => {
      GAEventTracker.sendEvent({
        category: '點擊Header',
        action: '點擊Menu內容',
        label,
      })
    }
  }
}

UnauthedHeader.defaultProps = {
  renderCenter: () => <SearchBar />,
  isVisiableMenu: true,
  isVisibleUser: true,
  isVisibleDropdown: true,

  lazyRender: false,
  classes: {},
  headerId: '',
}

UnauthedHeader.propTypes = {
  renderRight: PropTypes.func,
  renderCenter: PropTypes.func.isRequired,
  classes: PropTypes.object,
  isVisiableCityAndCategoryMenu: PropTypes.bool,
  isVisiableMenu: PropTypes.bool,
  isVisibleDropdown: PropTypes.bool,
  isVisibleUser: PropTypes.bool,
  setLoginModalOpenStatus: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map),
  location: PropTypes.string,
  headerId: PropTypes.string,
}

export default withLoginInfo(UnauthedHeader)
