import css from 'styled-jsx/css'

export default css`
  .search-input {
    display: flex;
    align-items: center;
    padding-left: 25px;
    .search-input-header {
      display: none;
    }
    :global(.search-button) {
      background-color: #0b5d90;
      border-radius: 26px;
      min-height: 30px;
      height: 30px;
      padding: 0px;
      min-width: fit-content;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 10px;
      margin-right: -10px;
      box-shadow: none;
      flex: none;
      box-shadow: rgba(22, 34, 51, 0.3) 0px 6px 16px;
    }
    :global(.search-button-text) {
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
    }
    :global(.search-icon) {
      color: #ffffff;
      width: 20px;
      height: 20px;
    }
  }
  .input-group {
    max-width: 500px;
    flex: 1;
    display: flex;
    border-radius: 34px;
    background-color: #ffffff;
    height: 44px;
    padding: 0px 16px;
    margin-right: 16px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 18px 0px;
    transition: all 180ms ease-in-out;

    :global(.input-search-icon) {
      display: none;
    }
    :global(.input-icon) {
      color: #0b5d90;
      z-index: 3;
    }
    :global(.autocomplete-root) {
      height: 100%;
      flex: 1;
    }
    :global(.autocomplete-suggestions) {
      margin-left: -8px;
      @media (max-width: 650px) {
        margin-left: 0;
      }
    }
    :global(.autocomplete-root.foreground) {
      z-index: 3;
      min-width: 60px;
    }
    :global(.suggestions) {
      color: #4a4a4a;
      width: 320px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      overflow-y: auto;
      max-height: 50vh;
    }
    @media (max-width: 780px) {
      :global(.suggestions) {
        display: none;
      }
      :global(.suggestions.composing-search) {
        display: block;
      }
    }
  }
  .divider {
    border-right: 1px solid #979797;
    height: 100%;
    width: 0px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .current-place-tag {
    flex: none;
    color: #4a4a4a;
    border-radius: 15px;
    padding: 0 10px 0 12px;
    border: solid 1px rgba(0, 41, 70, 0.11);
    background-color: #e1f4f7;
    display: flex;
    height: 28px;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    white-space: nowrap;
    cursor: pointer;
    .current-place-tag-content {
      max-width: 160px;
      overflow: hidden;
    }
    :global(.input-close-icon) {
      width: 14px;
      height: 19px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .restaurant-suggestion {
    display: flex;
    align-items: center;
  }
  .place-item,
  .keyword-item {
    padding: 12px;
    background-color: #ffffff;
    cursor: pointer;
    @media (max-width: 600px) {
      padding: 12px;
      font-size: 16px;
      border-bottom: 1px solid #efefef;
      margin-left: 4px;
    }
    .item-title {
      margin-bottom: 4px;
    }
    .item-info {
      font-size: 12px;
      color: #9b9b9b;
    }
    .item-photo {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 3px;
      flex: none;
      margin-right: 8px;
      margin-top: 2px;
    }
  }
  .place-item.current-location {
    color: #0073bb;
    display: flex;
    align-items: center;
    :global(.nearme-icon) {
      fill: #0073bb;
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
  .keyword-item.all-restaurant {
    display: flex;
    align-items: center;
    :global(.localdining-icon) {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  .place-item-active,
  .keyword-item-active {
    background-color: #e1f4f7;
  }
`
